import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
  TablePagination,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Switch,
  FormControlLabel,
  Modal,
  Grid,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  CircularProgress,
  Autocomplete
} from "@mui/material";
import { fetchCenters, updateCenter, deleteCenter, addCenter, deleteCenterPhoto } from "../../../Services/FirebaseUtils";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams, useNavigate } from "react-router-dom";
import ImageSliderDialog from "../../../Components/Private/ImageSliderDialog";
import CentersMap from "../../../Components/Private/CentersMap";
import MapIcon from '@mui/icons-material/Map';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CenterForm from '../../../Components/Private/CenterForm';
import OperatorForm from '../../../Components/Private/OperatorForm';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const Centers = () => {
  const [centers, setCenters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("centerName");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOperator, setSelectedOperator] = useState("all");
  const [selectedCity, setSelectedCity] = useState("all");
  const [operators, setOperators] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [currentView, setCurrentView] = useState(0);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openCenterModal, setOpenCenterModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionRow, setActionRow] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const amenitiesList = String(selectedCenter?.amenities || '').split(',');

  useEffect(() => {
    const loadCenters = async () => {
      const data = await fetchCenters();
      setCenters(data.centers);
      setOperators(data.operators);
      setCities(data.cities);

      // Check for operator in URL params
      const operatorParam = searchParams.get("operator");
      if (operatorParam) {
        setSelectedOperator(operatorParam);
      }
    };

    loadCenters();
  }, [searchParams]);

  useEffect(() => {
    if (showMap && !isMapInitialized && centers.length > 0) {
      setIsMapInitialized(true);
    }
  }, [showMap, centers]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOperatorChange = (event, newValue) => {
    setSelectedOperator(newValue ? newValue.operatorName : "all");
    setPage(0);
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue || "all");
    setPage(0);
  };

  const handleMapToggle = () => {
    setShowMap(prev => !prev);
  };

  const handleOpenModal = (center) => {
    setSelectedCenter(center);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCenter(null);
  };

  const handleActionsClick = (event, row) => {
    setActionRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
    setActionRow(null);
  };

  const handleView = () => {
    handleOpenModal(actionRow);
    handleActionsClose();
  };

  const handleEdit = () => {
    setSelectedCenter(actionRow);
    setEditMode(true);
    setOpenCenterModal(true);
    handleActionsClose();
  };

  const handleDelete = async () => {
    try {
      const result = await deleteCenter(actionRow.id);
      if (result.success) {
        // Refresh operators list
        const updatedCenters = await fetchCenters();
        setCenters(updatedCenters.centers);
      } else {
        console.error("Error deleting center:", result.error);
      }
    } catch (error) {
      console.error("Error deleting center:", error);
    }
    handleActionsClose();
  };

  const handleCenterSubmit = async (formData) => {
    try {
      let result;
      if (editMode) {
        result = await updateCenter(selectedCenter.id, formData);
      } else {
        // Find the operator ID based on the selected operator name
        const selectedOp = operators.find(op => op.operatorName === formData.operatorName);
        if (!selectedOp) {
          console.error('No operator selected');
          return;
        }
        result = await addCenter(formData, selectedOp.id);
      }

      if (result.success) {
        const data = await fetchCenters();
        setCenters(data.centers);
        setOperators(data.operators);
        setCities(data.cities);
        setOpenCenterModal(false);
        setEditMode(false);
        setSelectedCenter(null);
      } else {
        console.error("Error submitting center:", result.error);
      }
    } catch (error) {
      console.error("Error submitting center:", error);
    }
  };

  const handleViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setIsMapInitialized(newValue === 1);
  };

  const handleViewInventory = () => {
    if (actionRow && actionRow.centerName) {
      navigate(`/private/dashboard/inventory?center=${encodeURIComponent(actionRow.centerName)}`);
      handleActionsClose();
    }
  };

  const filteredCenters = centers.filter(center =>
    center.centerName?.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedOperator === "all" || center.operatorName === (typeof selectedOperator === 'object' ? selectedOperator.operatorName : selectedOperator)) &&
    (selectedCity === "all" || center.city === selectedCity)
  );

  const sortedCenters = filteredCenters.sort((a, b) => {
    const aValue = a[orderBy] || "";
    const bValue = b[orderBy] || "";

    if (order === "asc") {
      return aValue.localeCompare(bValue);
    }
    return bValue.localeCompare(aValue);
  });
  console.log("sortedCenters", sortedCenters.length);

  const paginatedCenters = sortedCenters.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Sort operators alphabetically
  const sortedOperators = [...operators].sort((a, b) => 
    a.operatorName.localeCompare(b.operatorName)
  );

  // Sort cities alphabetically
  const sortedCities = [...new Set(centers.map(center => center.city))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        {/* Search and Filter Bar */}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          minHeight: "48px"
        }}>
          {/* Left side: Search */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              placeholder="Search center..."
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{
                width: 300,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                  height: "36px",
                  "& fieldset": {
                    borderColor: "#E5E7EB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#D1D5DB",
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#9CA3AF", fontSize: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />

            <FormControl sx={{ minWidth: 200, mr: 2 }}>
              <Autocomplete
                value={sortedOperators.find(op => op.operatorName === selectedOperator) || null}
                onChange={handleOperatorChange}
                options={sortedOperators}
                getOptionLabel={(option) => option.operatorName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Operator"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ minWidth: 200, mr: 2 }}>
              <Autocomplete
                value={selectedCity === "all" ? null : selectedCity}
                onChange={handleCityChange}
                options={["all", ...sortedCities]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by City"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option === "all" ? "All Cities" : option}
                  </li>
                )}
              />
            </FormControl>
          </Box>

          {/* Right side: Add Center Button */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenCenterModal(true);
              setEditMode(false);
              setSelectedCenter(null);
            }}
            sx={{
              height: "36px",
              textTransform: "none",
              borderRadius: "6px",
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            Add Center
          </Button>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs
            value={currentView}
            onChange={handleViewChange}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                minHeight: '18px',
                color: '#6B7280',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
              }
            }}
          >
            <Tab
              icon={<TableRowsIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Table View"
            />
            <Tab
              icon={<MapIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Map View"
            />
          </Tabs>
        </Box>

        {/* Content based on selected view */}
        {currentView === 0 ? (
          <>
            {/* Table */}
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "centerName"}
                        direction={orderBy === "centerName" ? order : "asc"}
                        onClick={() => handleSort("centerName")}
                      >
                        Center Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "operatorName"}
                        direction={orderBy === "operatorName" ? order : "asc"}
                        onClick={() => handleSort("operatorName")}
                      >
                        Operator
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      City
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      Locality
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCenters.map((center) => (
                    <TableRow
                      key={center.id}
                      hover
                      sx={{
                        "&:hover": {
                          backgroundColor: "#F9FAFB",
                        },
                        "& .MuiTableCell-root": {
                          py: 1.5
                        }
                      }}
                    >
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {center.centerName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {center.operatorName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {center.city}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {center.locality}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton
                            size="small"
                            onClick={(event) => handleActionsClick(event, center)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleActionsClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem onClick={handleView}>
                              <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>View</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleEdit}>
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Edit</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleViewInventory}>
                              <ListItemIcon>
                                <CloudUploadIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>View Inventory</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <Box sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
              <TablePagination
                component="div"
                count={filteredCenters.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{
                  '.MuiTablePagination-toolbar': {
                    alignItems: 'center',
                    minHeight: 'unset',
                  },
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    margin: 0,
                  }
                }}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ height: 'calc(100vh - 300px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CentersMap
              centers={filteredCenters.filter(center => {
                const hasCoordinates = center.latitude && center.longitude;
                if (!hasCoordinates) {
                  console.log('Center missing coordinates:', center.centerName);
                }
                return hasCoordinates;
              })}
            />
          </Box>
        )}
        {/* Inventory Preview Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="center-modal-title"
          aria-describedby="center-modal-description"
        >
          <Box sx={modalStyle}>
            {/* Header */}
            <Box sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #E5E7EB'
            }}>
              <Typography variant="h6" component="h2">
                Center Details
              </Typography>
              <IconButton
                onClick={handleCloseModal}
                size="small"
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'text.primary',
                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Content */}
            {selectedCenter && (
              <Box sx={{ p: 3 }}>
                {/* Photos Grid */}
                {selectedCenter.photos && selectedCenter.photos.length > 0 && (
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Photos
                    </Typography>
                    <Grid container spacing={2}>
                      {selectedCenter.photos.map((photo, index) => (
                        <Grid item xs={3} key={index} sx={{ position: 'relative' }}>
                          <Box
                            component="img"
                            src={photo.url}
                            alt={photo.name || `Photo ${index + 1}`}
                            sx={{
                              width: '100%',
                              height: 120,
                              objectFit: 'cover',
                              borderRadius: 1,
                              cursor: 'pointer',
                              opacity: isDeleting ? 0.5 : 1
                            }}
                          />

                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={3}>
                  {/* Basic Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Basic Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Name</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedCenter.centerName || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Building Type</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedCenter.buildingType || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Building Grade</Typography>
                        <Typography variant="body1">{selectedCenter.buildingGrade || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Active</Typography>
                        <Typography variant="body1">{selectedCenter.active ? 'Yes' : 'No'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Location Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Location Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Address</Typography>
                        <Typography variant="body1" sx={{
                          p: 1.5,
                          bgcolor: 'grey.50',
                          borderRadius: 1
                        }}>{selectedCenter.address || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>City</Typography>
                        <Typography variant="body1">{selectedCenter.city || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Locality</Typography>
                        <Typography variant="body1">{selectedCenter.locality || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Google Maps Location</Typography>
                        <Typography variant="body1" sx={{
                          p: 1.5,
                          bgcolor: 'grey.50',
                          borderRadius: 1,
                          wordBreak: 'break-all'
                        }}>{selectedCenter.googleMapsLocation || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Contact Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Contact Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Name</Typography>
                        <Typography variant="body1">{selectedCenter.centerManagerName || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Phone</Typography>
                        <Typography variant="body1">{selectedCenter.centerManagerPhone || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Manager Email</Typography>
                        <Typography variant="body1">{selectedCenter.centerManagerEmail || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Additional Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Additional Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operational Hours</Typography>
                        <Typography variant="body1">{selectedCenter.operationalHours || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operational From</Typography>
                        <Typography variant="body1">{selectedCenter.operationalFrom || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>List Price</Typography>
                        <Typography variant="body1">₹{selectedCenter.listPrice || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Cafeteria Capacity</Typography>
                        <Typography variant="body1">{selectedCenter.cafeteriaCapacity || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Car Parking Cost</Typography>
                        <Typography variant="body1">{selectedCenter.carParkingCost || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Bike Parking Cost</Typography>
                        <Typography variant="body1">{selectedCenter.bikeParkingCost || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Description</Typography>
                        <Typography variant="body1" sx={{
                          p: 1.5,
                          bgcolor: 'grey.50',
                          borderRadius: 1,
                          minHeight: '60px'
                        }}>{selectedCenter.description || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Amenities</Typography>
                        <Typography variant="body1" sx={{
                          p: 1.5,
                          bgcolor: 'grey.50',
                          borderRadius: 1
                        }}>
                          {amenitiesList.length > 0
                            ? amenitiesList
                              .map(amenity => amenity.trim())
                              .filter(Boolean)
                              .join(', ')
                            : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Box>
            )}
          </Box>
        </Modal>


        {/* Image Slider Dialog */}
        <ImageSliderDialog
          open={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          images={selectedImages}
        />
        <CenterForm
          open={openCenterModal}
          onClose={() => setOpenCenterModal(false)}
          center={selectedCenter}
          editMode={editMode}
          initialData={selectedCenter}
          onSubmit={handleCenterSubmit}
          theme={theme}
          operators={operators}
        />
      </Box>
    </Card>
  );
};

export default Centers;
