import React from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Screen from '../../../Pages/Public/Screen';

const ProposalDialog = ({ open, onClose, proposal, proposalId, shortlistedCenters, schedules, isEditable = true }) => {
    const shortlistData = shortlistedCenters || [];
    const shortlistMap = shortlistData.reduce((acc, item) => {
        acc[item.inventoryId] = item.shortlistedBy || [];
        return acc;
    }, {});
    
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#ffffff',  // Changed to white to match non-edit mode
                }
            }}
        >
            <AppBar position="fixed" sx={{ backgroundColor: '#1F497D' }}>
                <Toolbar>
                    <Typography variant="h5" component="div" sx={{ flex: 1 }}>
                        {isEditable ? 'Edit Proposal' : 'View Proposal'}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ pt: 8, px: 0 }}>
                <Screen
                    proposal={proposal}
                    proposalId={proposalId}
                    shortlistedCenters={shortlistMap}
                    schedules={schedules}
                    isEditable={isEditable}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ProposalDialog;
