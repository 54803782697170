import React from 'react';
import {
  Popover,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const NotificationPopover = ({ anchorEl, onClose, notifications, onNotificationRead }) => {
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return moment(date).fromNow();
  };

  const formatNotificationText = (notification) => {
    switch (notification.type) {
      case 'shortlist':
        return `${notification.userEmail} shortlisted ${notification.centerName}`;
      case 'visit':
        return `${notification.userEmail} scheduled a visit to ${notification.centerName} on ${notification.visitDate} at ${notification.visitTime}`;
      default:
        return notification.message || 'New notification';
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          width: '400px',
          maxHeight: '500px',
          p: 2,
          borderRadius: 2,
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: -8,
            top: -8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
          Notifications
        </Typography>

        <List sx={{ width: '100%' }}>
          {notifications.length === 0 ? (
            <ListItem>
              <ListItemText
                primary="No notifications"
                sx={{ textAlign: 'center', color: 'text.secondary' }}
              />
            </ListItem>
          ) : (
            notifications.map((notification) => (
              <ListItem 
                key={notification.id}
                onClick={() => onNotificationRead(notification.id)}
                sx={{
                  bgcolor: notification.read ? 'transparent' : 'action.hover',
                  cursor: 'pointer',
                  borderRadius: 1,
                  mb: 1,
                  '&:hover': {
                    bgcolor: 'action.selected',
                  },
                }}
              >
                <ListItemText
                  primary={formatNotificationText(notification)}
                  secondary={formatTimestamp(notification.timestamp)}
                  primaryTypographyProps={{
                    sx: { fontWeight: notification.read ? 'normal' : 'bold' }
                  }}
                />
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Popover>
  );
};

export default NotificationPopover;
