import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addLead, updateLead, createProposal, updateLeadProfile } from '../../Services/FirebaseUtils';

// Async thunks
export const addNewLead = createAsyncThunk(
  'leads/addLead',
  async (leadData) => {
    const result = await addLead(leadData);
    if (!result.success) {
      throw new Error(result.error);
    }
    return result.lead;
  }
);

export const updateExistingLead = createAsyncThunk(
  'leads/updateLead',
  async ({ id, data }) => {
    const result = await updateLead(id, data);
    if (!result.success) {
      throw new Error(result.error);
    }
    return result.lead;
  }
);

export const createNewProposal = createAsyncThunk(
  'leads/createProposal',
  async (leadId) => {
    const result = await createProposal(leadId);
    if (!result.success) {
      throw new Error(result.error);
    }
    return result.proposal;
  }
);

export const generateLeadProfile = createAsyncThunk(
  'leads/generateProfile',
  async ({ leadId, profile }) => {
    const result = await updateLeadProfile(leadId, profile);
    if (!result.success) {
      throw new Error(result.error);
    }
    return { leadId, profile };
  }
);

const initialState = {
  items: [],
  loading: false,
  error: null,
  currentOperation: null
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeads: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // Add Lead
      .addCase(addNewLead.pending, (state) => {
        state.currentOperation = 'adding';
      })
      .addCase(addNewLead.fulfilled, (state) => {
        state.currentOperation = null;
        state.error = null;
      })
      .addCase(addNewLead.rejected, (state, action) => {
        state.currentOperation = null;
        state.error = action.error.message;
      })
      // Update Lead
      .addCase(updateExistingLead.pending, (state) => {
        state.currentOperation = 'updating';
      })
      .addCase(updateExistingLead.fulfilled, (state) => {
        state.currentOperation = null;
        state.error = null;
      })
      .addCase(updateExistingLead.rejected, (state, action) => {
        state.currentOperation = null;
        state.error = action.error.message;
      })
      // Create Proposal
      .addCase(createNewProposal.pending, (state) => {
        state.currentOperation = 'creating_proposal';
      })
      .addCase(createNewProposal.fulfilled, (state) => {
        state.currentOperation = null;
        state.error = null;
      })
      .addCase(createNewProposal.rejected, (state, action) => {
        state.currentOperation = null;
        state.error = action.error.message;
      })
      // Generate Profile
      .addCase(generateLeadProfile.pending, (state) => {
        state.currentOperation = 'generating_profile';
      })
      .addCase(generateLeadProfile.fulfilled, (state) => {
        state.currentOperation = null;
        state.error = null;
      })
      .addCase(generateLeadProfile.rejected, (state, action) => {
        state.currentOperation = null;
        state.error = action.error.message;
      });
  }
});

export const { setLeads, setLoading, setError } = leadsSlice.actions;
export default leadsSlice.reducer;
