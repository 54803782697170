import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  loading: false,
  error: null
};

const actionItemsSlice = createSlice({
  name: 'actionItems',
  initialState,
  reducers: {
    setActionItems: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setActionItems, setLoading, setError } = actionItemsSlice.actions;

export default actionItemsSlice.reducer;
