import react from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/Styles.css";
// import html2pdf from 'html2pdf.js';
import Dropdown from "react-bootstrap/Dropdown";

const Header = ({ proposal, proposalId }) => {
  console.log(proposalId);

  // Check if there is any visit schedule data
  const hasVisitSchedule = proposal?.visitSchedule && proposal.visitSchedule.length > 0;

  // const handleDownloadPDF = () => {
  //   const element = document.body;
  //   const opt = {
  //     margin: 0.5,
  //     filename: 'flex-proposal.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
  //   };

  //   html2pdf().set(opt).from(element).save();
  // };

  // Check if there are any shortlisted options using Firestore data


  return (
    <header className="text-white p-3 fixed-top" style={{ backgroundColor: "#1F497D" }}>
      <div className="container">
        <div className="row w-100 justify-content-between align-items-center">
          {/* Title */}
          <div className="col-12 col-md-6 d-none d-md-block text-center text-md-start mb-3 mb-md-0">
            <p className="mb-0 text-white">
              Flex Office Space Proposal
            </p>
          </div>

          {/* Menu Items Row */}
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center gap-4">
            {/* <a
              href="#office-proposal"
              className="text-white text-decoration-none header-menu"
            >
              Summary
            </a> */}
            <Dropdown>
              <Dropdown.Toggle
                variant="transparent"
                id="dropdown-basic"
                className="text-white header-menu"
              >
                Options Details
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {proposal?.proposedOptions?.map((option, index) => (
                  <Dropdown.Item key={index} href={`#${option.centerName}`}>
                    {option.centerName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            
            <a
              href="#Short-list"
              className="text-white text-decoration-none header-menu"
            >
              Shortlist
            </a>
            {hasVisitSchedule && (
              <a
                href="#visit-schedule"
                className="text-white text-decoration-none header-menu"
              >
                Visit Schedule
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
