import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ProposalDialog from '../../../Component/Private/ProposalDialog/ProposalDialog';
import { setupProposalListener } from '../../../Services/FirebaseUtils';

const EditProposal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const isEditable = location.state?.mode === 'edit';

  useEffect(() => {
    let unsubscribe = () => {};

    if (id) {
      unsubscribe = setupProposalListener(id, (proposalData) => {
        setProposal(proposalData);
        setLoading(false);
      });
    }

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    navigate('/private/dashboard/proposals');
  };

  if (loading || !proposal) {
    return null;
  }

  return (
    <ProposalDialog
      open={open}
      onClose={handleClose}
      proposal={proposal}
      proposalId={id}
      shortlistedCenters={proposal.shortlist || []}
      schedules={proposal.visitSchedule || []}
      isEditable={isEditable}
    />
  );
};

export default EditProposal;
