import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Container,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { loginUser } from '../../../firebase/auth';
import coworking from "../../../assest/image/coworking-space.avif";

const PrivateLogin = () => {
    const [credentials, setCredentials] = useState({
        emailOrPhone: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already logged in
        const userData = localStorage.getItem('userData');
        if (userData) {
            // If user is logged in, stay at /private
            navigate('/private', { replace: true });
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({
            ...prev,
            [name]: value
        }));
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const result = await loginUser(credentials.emailOrPhone, credentials.password);
            
            if (result.success) {
                // Get user data from Firestore
                const userDoc = await getDoc(doc(db, 'users', result.user.uid));
                
                
                localStorage.setItem('privateEmail', credentials.emailOrPhone);

                navigate('/private', { replace: true });
            } else {
                setError(result.error);
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{
            minHeight: '70vh',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '50%',
                backgroundColor: '#004D40',
            }
        }}>
            <Container maxWidth="lg" sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                zIndex: 1,
            }}>
                <Paper
                    elevation={3}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: { xs: '95%', md: '1100px' },
                        minHeight: { xs: 'auto', md: '500px' },
                        borderRadius: 2,
                        overflow: 'hidden',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            flex: '1 1 50%',
                            backgroundImage: `url(${coworking})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            minHeight: { xs: '200px', md: 'auto' },
                        }}
                    />
                    <Box
                        sx={{
                            flex: '1 1 50%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: { xs: 3, md: 4 },
                            backgroundColor: '#fff'
                        }}
                    >
                        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
                            <img
                                src="https://assets.gofloaters.com/logo.png"
                                alt="GoFloaters"
                                style={{ height: '50px' }}
                            />
                        </Box>
                        <Typography
                            variant="h4"
                            component="h1"
                            align="center"
                            sx={{
                                mb: 6,
                                fontWeight: 500,
                                color: '#1a1a1a',
                                fontSize: { xs: '1.75rem', md: '2rem' }
                            }}
                        >
                            Flex Fast
                        </Typography>

                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mb: 4}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="emailOrPhone"
                                label="Email"
                                name="emailOrPhone"
                                autoComplete="email"
                                autoFocus
                                value={credentials.emailOrPhone}
                                onChange={handleChange}
                                disabled={loading}
                                error={!!error}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={credentials.password}
                                onChange={handleChange}
                                disabled={loading}
                                error={!!error}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    mt: 3,
                                    py: 1.8,
                                    backgroundColor: '#004D40',
                                    '&:hover': {
                                        backgroundColor: '#00695C',
                                    },
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    borderRadius: 1,
                                }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    'Sign in'
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default PrivateLogin;
