import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Grid,
} from '@mui/material';

const LeadForm = ({ 
  lead = {
    companyName: '',
    companyWebsite: '',
    status: '',
    category: '',
    source: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    contactLinkedIn: '',
    city: '',
    lastActionDate: '',
    nextActionDate: '',
  }, 
  handleChange, 
  formErrors = {}, 
  statusOptions = [], 
  categoryOptions = [], 
  sourceOptions = [], 
  cityOptions = [] 
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
      {/* Company Information */}
      <Typography variant="subtitle2" color="textSecondary">Company Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Company Name"
            value={lead.companyName}
            onChange={(e) => handleChange('companyName')(e)}
            error={!!formErrors.companyName}
            helperText={formErrors.companyName}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Company Website"
            value={lead.companyWebsite}
            onChange={(e) => handleChange('companyWebsite')(e)}
            placeholder="https://"
          />
        </Grid>
      </Grid>

      {/* Lead Status */}
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>Lead Status</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth required error={!!formErrors.status}>
            <InputLabel>Status</InputLabel>
            <Select
              value={lead.status}
              onChange={(e) => handleChange('status')(e)}
              label="Status"
            >
              {statusOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {formErrors.status && <FormHelperText>{formErrors.status}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth required error={!!formErrors.category}>
            <InputLabel>Category</InputLabel>
            <Select
              value={lead.category}
              onChange={(e) => handleChange('category')(e)}
              label="Category"
            >
              {categoryOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {formErrors.category && <FormHelperText>{formErrors.category}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth required error={!!formErrors.source}>
            <InputLabel>Source</InputLabel>
            <Select
              value={lead.source}
              onChange={(e) => handleChange('source')(e)}
              label="Source"
            >
              {sourceOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {formErrors.source && <FormHelperText>{formErrors.source}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      {/* Contact Information */}
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>Contact Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Contact Name"
            value={lead.contactName}
            onChange={(e) => handleChange('contactName')(e)}
            error={!!formErrors.contactName}
            helperText={formErrors.contactName}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Contact Phone"
            value={lead.contactPhone}
            onChange={(e) => handleChange('contactPhone')(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Contact Email"
            value={lead.contactEmail}
            onChange={(e) => handleChange('contactEmail')(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Contact LinkedIn"
            value={lead.contactLinkedIn}
            onChange={(e) => handleChange('contactLinkedIn')(e)}
            placeholder="https://linkedin.com/in/"
          />
        </Grid>
      </Grid>

      {/* Location and Dates */}
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>Location & Follow-up</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth required error={!!formErrors.city}>
            <InputLabel>City</InputLabel>
            <Select
              value={lead.city}
              onChange={(e) => handleChange('city')(e)}
              label="City"
            >
              {cityOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {formErrors.city && <FormHelperText>{formErrors.city}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Last Action Date"
            type="date"
            value={lead.lastActionDate}
            onChange={(e) => handleChange('lastActionDate')(e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Next Action Date"
            type="date"
            value={lead.nextActionDate}
            onChange={(e) => handleChange('nextActionDate')(e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadForm;
