import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Stack,
  Alert,
  Snackbar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Add as AddIcon,
  MoreVert as MoreVertIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material';
import { auth, db } from '../../../firebase/config';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { 
  subscribeToMetaData, 
  addCity, 
  updateCity, 
  deleteCity,
  subscribeToUserByEmail
} from '../../../Services/FirebaseUtils';

const Settings = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [userId, setUserId] = useState(null);
  const [metaData, setMetaData] = useState({ cities: [], micromarkets: [] });
  const [editingCity, setEditingCity] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isMicromarketsDialogOpen, setIsMicromarketsDialogOpen] = useState(false);
  const [cityForm, setCityForm] = useState({
    name: '',
    state: '',
    micromarkets: []
  });
  const [newMicromarket, setNewMicromarket] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  // Setup real-time listener for meta data
  useEffect(() => {
    const unsubscribe = subscribeToMetaData(
      (data) => setMetaData(data),
      (error) => {
        console.error("Error listening to meta data:", error);
        setSnackbar({
          open: true,
          message: 'Error listening to updates',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, []);

  // Setup real-time listener for user data based on auth email
  useEffect(() => {
    if (!auth.currentUser?.email) {
      console.log("No authenticated user email found");
      return;
    }

    console.log("Looking for user with email:", auth.currentUser.email);
    
    const unsubscribe = subscribeToUserByEmail(
      auth.currentUser.email,
      (userData) => {
        console.log("Found user data:", userData);
        setUserId(userData.id);
        setUserDetails({
          name: userData.name || "",
          email: userData.email || "",
          role: userData.role || ""
        });
      },
      (error) => {
        console.error("Error fetching user:", error);
        setSnackbar({
          open: true,
          message: 'Error fetching user data',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, [auth.currentUser?.email]);

  // Subscribe to specific user document changes
  useEffect(() => {
    if (!userId) return;

    console.log("Subscribing to user document:", userId);
    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(userRef, 
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          console.log("User document updated:", userData);
          setUserDetails({
            name: userData.name || "",
            email: userData.email || "",
            role: userData.role || ""
          });
        }
      },
      (error) => {
        console.error("Error watching user document:", error);
        setSnackbar({
          open: true,
          message: 'Error watching user data',
          severity: 'error'
        });
      }
    );

    return () => unsubscribe();
  }, [userId]);

  console.log("Current userDetails:", userDetails);

  const getMicromarketsForCity = (cityName) => {
    return metaData.micromarkets.filter(m => m.city === cityName);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleUserDetailsChange = (field) => (event) => {
    setUserDetails({
      ...userDetails,
      [field]: event.target.value,
    });
  };

  const handleUpdateProfile = async () => {
    if (!userId) return;

    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        name: userDetails.name,
        email: userDetails.email,
        role: userDetails.role
      });
      
      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      setSnackbar({
        open: true,
        message: 'Error updating profile',
        severity: 'error'
      });
    }
  };

  const handleMenuOpen = (event, city) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedCity(city);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEditCity = () => {
    setEditingCity(selectedCity);
    setCityForm({
      name: selectedCity,
      state: '',
      micromarkets: getMicromarketsForCity(selectedCity).map(m => m.micromarket)
    });
    setIsDialogOpen(true);
    handleMenuClose();
  };

  const handleEditMicromarkets = () => {
    setEditingCity(selectedCity);
    setCityForm({
      name: selectedCity,
      state: '',
      micromarkets: getMicromarketsForCity(selectedCity).map(m => m.micromarket)
    });
    setIsMicromarketsDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleAddCity = () => {
    setEditingCity(null);
    setCityForm({
      name: '',
      state: '',
      micromarkets: []
    });
    setIsDialogOpen(true);
  };

  const handleCityFormChange = (field) => (event) => {
    setCityForm({
      ...cityForm,
      [field]: event.target.value
    });
  };

  const handleAddMicromarket = () => {
    if (newMicromarket.trim()) {
      setCityForm(prev => ({
        ...prev,
        micromarkets: [...prev.micromarkets, newMicromarket.trim()]
      }));
      setNewMicromarket('');
    }
  };

  const handleRemoveMicromarket = (micromarket) => {
    setCityForm(prev => ({
      ...prev,
      micromarkets: prev.micromarkets.filter(m => m !== micromarket)
    }));
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteCity(selectedCity);
      setSnackbar({
        open: true,
        message: 'City deleted successfully',
        severity: 'success'
      });
      setDeleteConfirmOpen(false);
    } catch (error) {
      console.error('Error deleting city:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting city',
        severity: 'error'
      });
    }
  };

  const handleSaveCity = async () => {
    try {
      if (editingCity) {
        await updateCity(editingCity, cityForm.name, cityForm.micromarkets);
      } else {
        await addCity(cityForm.name, cityForm.micromarkets);
      }

      setSnackbar({
        open: true,
        message: `City ${editingCity ? 'updated' : 'added'} successfully`,
        severity: 'success'
      });
      setIsDialogOpen(false);
      setIsMicromarketsDialogOpen(false);
    } catch (error) {
      console.error('Error saving city:', error);
      setSnackbar({
        open: true,
        message: `Error ${editingCity ? 'updating' : 'adding'} city`,
        severity: 'error'
      });
    }
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      p: 3,
      backgroundColor: 'white',
      borderRadius: 1,
    }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Settings</Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Cities" />
          <Tab label="User" />
        </Tabs>
      </Box>

      <Box sx={{ flex: 1, mt: 3 }}>
        {/* Cities Tab */}
        {currentTab === 0 && (
          <Box>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddCity}
              >
                Add City
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ height: '100%', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>City Name</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>State</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Micromarkets</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metaData.cities.map((city) => (
                    <TableRow key={city} hover>
                      <TableCell>{city}</TableCell>
                      <TableCell>{city.state || '-'}</TableCell>
                      <TableCell>
                        {getMicromarketsForCity(city).length || 0} micromarkets
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          size="small"
                          onClick={(e) => handleMenuOpen(e, city)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* User Tab */}
        {currentTab === 1 && (
          <Box sx={{ maxWidth: 500 }}>
            <TextField
              fullWidth
              label="Name"
              value={userDetails.name}
              onChange={handleUserDetailsChange("name")}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Email"
              value={userDetails.email}
              onChange={handleUserDetailsChange("email")}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Role"
              value={userDetails.role}
              onChange={handleUserDetailsChange("role")}
              sx={{ mb: 3 }}
            />
            <Button 
              variant="contained" 
              onClick={handleUpdateProfile}
              sx={{ minWidth: 150 }}
            >
              Update Profile
            </Button>
          </Box>
        )}
      </Box>

      {/* Action Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditCity}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit City</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleEditMicromarkets}>
          <ListItemIcon>
            <LocationIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Micromarkets</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {/* City Edit Dialog */}
      <Dialog 
        open={isDialogOpen} 
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingCity ? 'Edit City' : 'Add New City'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="City Name"
              value={cityForm.name}
              onChange={handleCityFormChange('name')}
            />
            <TextField
              fullWidth
              label="State"
              value={cityForm.state}
              onChange={handleCityFormChange('state')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveCity}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Micromarkets Edit Dialog */}
      <Dialog 
        open={isMicromarketsDialogOpen} 
        onClose={() => setIsMicromarketsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Micromarkets for {editingCity}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Micromarkets</Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 2 }}>
              {cityForm.micromarkets.map((micromarket, index) => (
                <Chip
                  key={index}
                  label={micromarket}
                  onDelete={() => handleRemoveMicromarket(micromarket)}
                  sx={{ mb: 1 }}
                />
              ))}
            </Stack>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                size="small"
                label="Add Micromarket"
                value={newMicromarket}
                onChange={(e) => setNewMicromarket(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddMicromarket();
                  }
                }}
              />
              <Button
                variant="outlined"
                onClick={handleAddMicromarket}
              >
                Add
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsMicromarketsDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveCity}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Delete City</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedCity}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
