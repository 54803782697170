import React from 'react';
import conculsion from "../../../assest/image/footer.png";
import Logo from "../../../assest/image/CBRE_green.png"
import ThankYou from "../../../assest/image/thank-you.png"
import "../../../Styles/Conclusion.css"

const backgroundStyle = {
    height: '100vh',
    width: '100%',
    // backgroundImage: `url(${ThankYou})`,
    backgroundSize: "contains",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center", 
  };

export default function Conculsion() {
    return (
        <div className="conclusion-section" style={backgroundStyle}>
            {/* <div className="conclusion-overlay"></div> */}
            <div className="container position-relative">
                <div className="row align-items-center min-vh-100">
                    {/* Left Column */}
                    <div className="col-12 col-lg-5 text-center text-lg-start mb-4 mb-lg-0">
                        <img
                            src={'https://assets.gofloaters.com/logo.png'}
                            alt="GoFloaters Logo"
                            className="mb-3"
                            style={{ maxWidth: '40%', height: 'auto' }}
                        />
                        <h1 className="display-5 mt-4">
                            For queries please connect
                        </h1>
                        <div className="mt-4">
                            <p className="name mb-2 fw-bold">Shyam Sundar</p>
                            <p className="span">Founder & CEO, GoFloaters</p>
                            <p className="span">shyam@gofloaters.com</p>
                            {/* <p className="span">+91-9811664738</p> */}
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-12 col-lg-7">
                        <div className=" text-center  mb-5">
                            <img
                                src={conculsion}
                                alt="Office Space"
                                className="img-fluid rounded"
                                style={{ maxWidth: '90%', height: 'auto' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
