import React from 'react';
import './MobileNavigation.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBuilding,
  faLocationDot,
  faStore,
  faCircleInfo,
  faHouse
} from "@fortawesome/free-solid-svg-icons";

const MobileNavigation = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="mobile-navigation">
      <button onClick={() => scrollToSection('office-proposal')} className="nav-item">
        <span className="nav-icon">
          <FontAwesomeIcon icon={faHouse} />
        </span>
        <span className="nav-label">Proposal</span>
      </button>
      <button onClick={() => scrollToSection('recommended-locations')} className="nav-item">
        <span className="nav-icon">
          <FontAwesomeIcon icon={faLocationDot} />
        </span>
        <span className="nav-label">Locations</span>
      </button>
      <button onClick={() => scrollToSection('recommended-spaces')} className="nav-item">
        <span className="nav-icon">
          <FontAwesomeIcon icon={faBuilding} />
        </span>
        <span className="nav-label">Options</span>
      </button>
      <button onClick={() => scrollToSection('center-details')} className="nav-item">
        <span className="nav-icon">
          <FontAwesomeIcon icon={faCircleInfo} />
        </span>
        <span className="nav-label">Details</span>
      </button>
    </nav>
  );
};

export default MobileNavigation;
