import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
  TextField,
  InputAdornment,
  TablePagination,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Modal,
  Grid,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
  Snackbar,
  Alert,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete
} from "@mui/material";
import {
  fetchInventories,
  fetchCenters,
  fetchOperators,
  deleteInventory,
  updateInventory,
  addInventory,
  deleteInventoryPhoto,
  checkInventoryAvailability,
} from "../../../Services/FirebaseUtils";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import { AutoFixHigh as SparkleIcon } from '@mui/icons-material';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import ImageSliderDialog from "../../../Components/Private/ImageSliderDialog";
import InventoryForm from "../../../Components/Private/InventoryForm";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InventoryMap from "../../../Components/Private/InventoryMap";
import ImportInventoryModal from "../../../Component/Private/ImportInventoryModal";
import AddToProposalDialog from '../../../Component/Private/AddToProposalDialog/AddToProposalDialog';
import { transformInventoryToOption } from '../../../Services/Service';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const formatFirestoreTimestamp = (timestamp) => {
  if (!timestamp || !timestamp.toDate) return 'Not available';
  return timestamp.toDate().toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

const Inventories = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const centerFromUrl = queryParams.get('center');

  const [inventories, setInventories] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("inventoryName");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOperator, setSelectedOperator] = useState("all");
  const [selectedCenter, setSelectedCenter] = useState(centerFromUrl || "all");
  const [selectedCity, setSelectedCity] = useState("all");
  const [operators, setOperators] = useState([]);
  const [centers, setCenters] = useState([]);
  const [cities, setCities] = useState([]);
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionRow, setActionRow] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentView, setCurrentView] = useState(0);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [addToProposalOpen, setAddToProposalOpen] = useState(false);
  const [proposalOption, setProposalOption] = useState(null);

  const theme = useTheme();
  const [searchParams] = useSearchParams();
  // const { items: proposals } = useSelector((state) => state.proposals);

  const handleViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setIsMapInitialized(newValue === 1);
  };

  useEffect(() => {
    if (showMap && !isMapInitialized && centers.length > 0) {
      setIsMapInitialized(true);
    }
  }, [showMap, centers]);

  const handleDeletePhoto = async (photoUrl) => {
    // Confirm deletion

    setIsDeleting(true);
    try {
      // Call the delete photo function
      const result = await deleteInventoryPhoto(selectedInventory.id, photoUrl);

      if (result.success) {
        // Update the local state to remove the deleted photo
        const updatedPhotos = selectedInventory.photos.filter(photo => photo.url !== photoUrl);
        setSelectedInventory(prevInventory => ({
          ...prevInventory,
          photos: updatedPhotos
        }));

        // Optional: Show a success message
        alert('Photo deleted successfully');
      } else {
        // Show error message
        alert(result.message);
        console.error('Deletion error details:', result.errorDetails);
      }
    } catch (error) {
      console.error('Error deleting photo:', error);
      alert('Failed to delete photo');
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const [inventoriesData, centersData, operatorsData] = await Promise.all([
          fetchInventories(),
          fetchCenters(),
          fetchOperators()
        ]);

        setInventories(inventoriesData?.inventories || []);
        setCenters(centersData?.centers || []);
        setOperators(operatorsData?.operators || []);

        // Extract unique cities from centers
        const uniqueCities = [...new Set(centersData?.centers?.map(center => center.city))].filter(Boolean);
        setCities(uniqueCities || []);

        // Set center filter from URL if present
        const centerParam = queryParams.get('center');
        if (centerParam) {
          const decodedCenterName = decodeURIComponent(centerParam);
          setSelectedCenter(decodedCenterName);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, [searchParams]);

  // Extract unique operator names for the filter
  const uniqueOperators = [...new Set(Array.isArray(operators) ?
    operators.map(op => op.operatorName).filter(Boolean) : [])];

  // Extract unique center names for the filter
  const uniqueCenters = [...new Set(Array.isArray(centers) ?
    centers.map(center => center.centerName).filter(Boolean) : [])];

  // Sort operators, centers, and cities alphabetically
  const sortedOperators = [...new Set(inventories.map(inv => inv.operatorName))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  const sortedCenters = [...new Set(inventories.map(inv => inv.centerName))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  const sortedCities = [...new Set(inventories.map(inv => inv.city))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (inventory) => {
    setSelectedInventory(inventory);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedInventory(null);
  };

  const handleActionsClick = (event, row) => {
    setActionRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
    setActionRow(null);
  };

  const handleView = () => {
    setSelectedInventory(actionRow);
    setOpenModal(true);
    handleActionsClose();
  };

  const handleEdit = () => {
    setSelectedInventory(actionRow);
    setEditMode(true);
    setOpenInventoryModal(true);
    handleActionsClose();
  };

  const handleDelete = async () => {
    try {
      const result = await deleteInventory(actionRow.id);
      if (result.success) {
        const data = await fetchInventories();
        setInventories(data.inventories || []);
      } else {
        console.error("Error deleting inventory:", result.error);
      }
    } catch (error) {
      console.error("Error deleting inventory:", error);
    }
    handleActionsClose();
  };

  const handleInventorySubmit = async (formData) => {
    try {
      let result;
      if (editMode) {
        result = await updateInventory(selectedInventory.id, formData);
      } else {
        result = await addInventory(formData);
      }

      if (result.success) {
        const data = await fetchInventories();
        setInventories(data.inventories || []);
        setOpenInventoryModal(false);
        setEditMode(false);
        setSelectedInventory(null);
      } else {
        console.error("Error submitting inventory:", result.error);
      }
    } catch (error) {
      console.error("Error submitting inventory:", error);
    }
  };

  const handleOperatorChange = (event, newValue) => {
    setSelectedOperator(newValue || "all");
    setPage(0);
  };

  const handleCenterChange = (event, newValue) => {
    setSelectedCenter(newValue || "all");
    setPage(0);
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue || "all");
    setPage(0);
  };

  const handleAddToProposal = async () => {
    try {
      const option = await transformInventoryToOption(actionRow);
      setProposalOption(option);
      setAddToProposalOpen(true);
      handleActionsClose();
    } catch (error) {
      console.error('Error in handleAddToProposal:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Error adding to proposal',
        severity: 'error'
      });
    }
  };

  const handleAddToProposalClose = () => {
    setAddToProposalOpen(false);
    setProposalOption(null);
  };

  const handleAddToProposalResult = (result) => {
    if (result.success) {
      setSnackbar({
        open: true,
        message: result.message,
        severity: 'success'
      });
    }
  };

  const handleCheckAvailability = async () => {
    if (!actionRow) {
      setSnackbar({
        open: true,
        message: 'No inventory selected',
        severity: 'error'
      });
      return;
    }
    try {
      setLoading(true);
      const result = await checkInventoryAvailability(actionRow);
      if (result.success) {
        setSnackbar({
          open: true,
          message: 'Calling center manager to check availability...',
          severity: 'info'
        });
      } else {
        setSnackbar({
          open: true,
          message: result.error || 'Failed to initiate availability check',
          severity: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error checking availability: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
      handleActionsClose();
    }
  };

  // Filter inventories based on selected filters
  const filteredInventories = inventories.filter((inventory) => {
    const matchesOperator = selectedOperator === "all" || inventory.operatorName === selectedOperator;
    const matchesCenter = selectedCenter === "all" || inventory.centerName === selectedCenter;
    const matchesCity = selectedCity === "all" || inventory.city === selectedCity;

    return matchesOperator && matchesCenter && matchesCity;
  });

  // Sort filtered inventories
  const sortedInventories = [...filteredInventories].sort((a, b) => {
    const aValue = (a[orderBy] || "").toString();
    const bValue = (b[orderBy] || "").toString();

    if (order === "asc") {
      return aValue.localeCompare(bValue);
    }
    return bValue.localeCompare(aValue);
  });

  // Get paginated data
  const paginatedInventories = sortedInventories.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Inside your Inventories component
  const inventoriesWithCenterData = filteredInventories.map(inventory => {
    // Find the corresponding center for this inventory
    const centerData = centers.find(center => center.id === inventory.centerId);
    // Add center coordinates to the inventory object
    return {
      ...inventory,
      latitude: centerData?.latitude,
      longitude: centerData?.longitude,
      listPrice: centerData?.listPrice,
      centerName: centerData?.centerName // Optional: add center name if needed
    };
  });


  // When rendering Map View, use inventoriesWithCenterData instead of filteredInventories
  const mapInventories = inventoriesWithCenterData.filter(
    inventory => inventory.latitude && inventory.longitude
  );
  console.log("mapInventories", mapInventories.length);

  const getInventoryCheckStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'inprogress':
        return 'warning';
      case 'completed':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'default';
    }
  };

  const getInventoryStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'available':
        return 'success';
      case 'not available':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Box sx={{ px: 2, py: 1 }}>
        {/* Toolbar */}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          minHeight: "48px"
        }}>
          {/* Filters */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/* Operator Filter */}
            <FormControl sx={{ minWidth: 200, mr: 2 }}>
              <Autocomplete
                value={selectedOperator === "all" ? null : selectedOperator}
                onChange={handleOperatorChange}
                options={["all", ...sortedOperators]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Operator"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option === "all" ? "All Operators" : option}
                  </li>
                )}
              />
            </FormControl>

            {/* Center Filter */}
            <FormControl sx={{ minWidth: 200, mr: 2 }}>
              <Autocomplete
                value={selectedCenter === "all" ? null : selectedCenter}
                onChange={handleCenterChange}
                options={["all", ...sortedCenters]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Center"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option === "all" ? "All Centers" : option}
                  </li>
                )}
              />
            </FormControl>

            {/* City Filter */}
            <FormControl sx={{ minWidth: 200, mr: 2 }}>
              <Autocomplete
                value={selectedCity === "all" ? null : selectedCity}
                onChange={handleCityChange}
                options={["all", ...sortedCities]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by City"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option === "all" ? "All Cities" : option}
                  </li>
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenInventoryModal(true)}
              sx={{
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: theme.palette.primary.dark
                }
              }}
            >
              Add Inventory
            </Button>
          </Box>
        </Box>

        {/* Separator Line */}
        <Box sx={{
          height: "1px",
          backgroundColor: "#e0e0e0",
          width: "calc(100% + 32px)",
          mx: -2,
          mb: 2
        }} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs
            value={currentView}
            onChange={handleViewChange}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                minHeight: '48px',
                color: '#6B7280',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
              }
            }}
          >
            <Tab
              icon={<TableRowsIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Table View"
            />
            <Tab
              icon={<MapIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Map View"
            />
          </Tabs>
        </Box>
        {currentView === 0 ? (
          <>

            {/* Table */}
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: (theme) => `${theme.palette.primary.main}08` }}>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "inventoryName"}
                        direction={orderBy === "inventoryName" ? order : "asc"}
                        onClick={() => handleSort("inventoryName")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "centerName"}
                        direction={orderBy === "centerName" ? order : "asc"}
                        onClick={() => handleSort("centerName")}
                      >
                        Center
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "inventoryType"}
                        direction={orderBy === "inventoryType" ? order : "asc"}
                        onClick={() => handleSort("inventoryType")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "size"}
                        direction={orderBy === "size" ? order : "asc"}
                        onClick={() => handleSort("size")}
                      >
                        Size
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "inventoryCheckStatus"}
                        direction={orderBy === "inventoryCheckStatus" ? order : "asc"}
                        onClick={() => handleSort("inventoryCheckStatus")}
                      >
                        Inventory Check
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      <TableSortLabel
                        active={orderBy === "inventoryStatus"}
                        direction={orderBy === "inventoryStatus" ? order : "asc"}
                        onClick={() => handleSort("inventoryStatus")}
                      >
                        Inventory Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{
                      fontWeight: 500,
                      color: "#6B7280",
                      borderBottom: "1px solid #E5E7EB",
                      py: 1.5
                    }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedInventories.map((inventory) => (
                    <TableRow
                      key={inventory.id}
                      hover
                      sx={{
                        "&:hover": {
                          backgroundColor: "#F9FAFB",
                        },
                        "& .MuiTableCell-root": {
                          py: 1.5
                        }
                      }}
                    >
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {inventory.inventoryName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {inventory.centerName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {inventory.inventoryType}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Typography variant="body1">
                          {inventory.size} seats
                        </Typography>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Tooltip title={`Last Checked: ${formatFirestoreTimestamp(inventory.lastCallInitiatedTime)}`}>
                          <Chip
                            label={inventory.inventoryCheckStatus || 'Not Checked'}
                            color={getInventoryCheckStatusColor(inventory.inventoryCheckStatus)}
                            size="small"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <Tooltip title={`Last Response: ${formatFirestoreTimestamp(inventory.lastCallRespondedTime)}`}>
                          <Chip
                            label={inventory.inventoryStatus || 'Unknown'}
                            color={getInventoryStatusColor(inventory.inventoryStatus)}
                            size="small"
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{
                        borderBottom: "1px solid #E5E7EB",
                        color: "#111827",
                        py: 1.5
                      }}>
                        <IconButton
                          size="small"
                          onClick={(event) => handleActionsClick(event, inventory)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
              <TablePagination
                component="div"
                count={filteredInventories.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{
                  '.MuiTablePagination-toolbar': {
                    alignItems: 'center',
                    minHeight: 'unset',
                  },
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    margin: 0,
                  }
                }}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ height: 'calc(100vh - 300px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <InventoryMap
              inventories={mapInventories}
            />
          </Box>
        )}

        {/* Actions Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleActionsClose}
        >
          <MenuItem onClick={handleView}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            View
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={handleCheckAvailability}>
            <ListItemIcon>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            Check Availability
            <ListItemIcon>
              <SparkleIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem onClick={handleAddToProposal}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            Add to proposal
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        {/* Inventory Preview Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="inventory-modal-title"
          aria-describedby="inventory-modal-description"
        >
          <Box sx={modalStyle}>
            {/* Header */}
            <Box sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #E5E7EB'
            }}>
              <Typography variant="h6" component="h2">
                Inventory Details
              </Typography>
              <IconButton
                onClick={handleCloseModal}
                size="small"
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'text.primary',
                    bgcolor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Content */}
            {selectedInventory && (
              <Box sx={{ p: 3 }}>
                {/* Photos Grid */}
                {selectedInventory.photos && selectedInventory.photos.length > 0 && (
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Photos
                    </Typography>
                    <Grid container spacing={1}>
                      {selectedInventory.photos.map((photo, index) => (
                        <Grid item xs={3} key={index} sx={{ position: 'relative' }}>
                          <Box
                            component="img"
                            src={photo.url}
                            alt={photo.name || `Photo ${index + 1}`}
                            sx={{
                              width: '100%',
                              height: 120,
                              objectFit: 'cover',
                              borderRadius: 1,
                              cursor: 'pointer',
                              opacity: isDeleting ? 0.5 : 1
                            }}
                          />

                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={3}>
                  {/* Basic Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Basic Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Inventory Name</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedInventory.inventoryName || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Inventory Type</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedInventory.inventoryType || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Size</Typography>
                        <Typography variant="body1">{selectedInventory.size || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Active</Typography>
                        <Typography variant="body1">{selectedInventory.active ? 'Yes' : 'No'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Center Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Center Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Center Name</Typography>
                        <Typography variant="body1">{selectedInventory.centerName || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Operator</Typography>
                        <Typography variant="body1">{selectedInventory.operator || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>City</Typography>
                        <Typography variant="body1">{selectedInventory.city || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Locality</Typography>
                        <Typography variant="body1">{selectedInventory.locality || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Additional Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Additional Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Availability Date</Typography>
                        <Typography variant="body1">{selectedInventory.availabilityDate || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Last Updated Date</Typography>
                        <Typography variant="body1">{selectedInventory.lastUpdatedDate || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Floor</Typography>
                        <Typography variant="body1">{selectedInventory.floor || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Section</Typography>
                        <Typography variant="body1">{selectedInventory.section || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Cabin Number</Typography>
                        <Typography variant="body1">{selectedInventory.cabinNumber || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Quantity</Typography>
                        <Typography variant="body1">{selectedInventory.quantity || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Description</Typography>
                        <Typography variant="body1" sx={{
                          p: 1.5,
                          bgcolor: 'grey.50',
                          borderRadius: 1
                        }}>{selectedInventory.description || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Record Information */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                      Record Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Added Time</Typography>
                        <Typography variant="body1">{new Date(selectedInventory.addedTime).toLocaleString()}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Added User</Typography>
                        <Typography variant="body1">{selectedInventory.addedUser || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Modified Time</Typography>
                        <Typography variant="body1">{new Date(selectedInventory.modifiedTime).toLocaleString()}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Modified User</Typography>
                        <Typography variant="body1">{selectedInventory.modifiedUser || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Record Status</Typography>
                        <Typography variant="body1">{selectedInventory.recordStatus || 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>Record Type</Typography>
                        <Typography variant="body1">{selectedInventory.recordType || 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Modal>

        {/* Image Slider Dialog */}
        <ImageSliderDialog
          open={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          images={selectedImages}
        />

        <ImportInventoryModal
          centers={centers}
          operators={operators}
          theme={theme}
          onSubmit={handleInventorySubmit}
          initialData={selectedInventory}
          editMode={editMode}
        />

        {/* Inventory Form Modal */}
        <InventoryForm
          open={openInventoryModal}
          onClose={() => {
            setOpenInventoryModal(false);
            setEditMode(false);
            setSelectedInventory(null);
          }}
          centers={centers}
          operators={operators}
          theme={theme}
          onSubmit={handleInventorySubmit}
          initialData={selectedInventory}
          editMode={editMode}
        />

        <AddToProposalDialog
          open={addToProposalOpen}
          onClose={handleAddToProposalClose}
          option={proposalOption}
          onResult={handleAddToProposalResult}
        />

      </Box>

    </Card>
  );
};

export default Inventories;
