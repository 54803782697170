import React, { useState } from 'react';
import Modal from "react-modal";

// Set the app element for react-modal
if (typeof window !== 'undefined') {
    Modal.setAppElement('#root');
}

const PhotoModal = ({ 
    isOpen, 
    onClose, 
    photos, 
    initialIndex = 0 
}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);

    const modalStyles = {
        content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 0,
            border: 'none',
            background: 'rgba(0, 0, 0, 0.95)',
            overflow: 'hidden',
            zIndex: 9999
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.95)',
            zIndex: 9998
        }
    };

    // Handle thumbnail click
    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    // Handle next image
    const handleNextImage = () => {
        const nextIndex = (currentImageIndex + 1) % photos.length;
        setCurrentImageIndex(nextIndex);
    };

    // Handle previous image
    const handlePrevImage = () => {
        const prevIndex = (currentImageIndex - 1 + photos.length) % photos.length;
        setCurrentImageIndex(prevIndex);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={modalStyles}
            contentLabel="Image Modal"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            parentSelector={() => document.body}
        >
            {photos && photos.length > 0 && (
                <div style={{ 
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    color: 'white'
                }}>
                    {/* Close button */}
                    <button
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            background: 'transparent',
                            border: 'none',
                            color: 'white',
                            fontSize: '32px',
                            cursor: 'pointer',
                            zIndex: 9999,
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        ×
                    </button>

                    {/* Navigation buttons */}
                    <button
                        onClick={handlePrevImage}
                        style={{
                            position: 'absolute',
                            left: '20px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'rgba(255, 255, 255, 0.1)',
                            border: 'none',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer',
                            fontSize: '30px',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'background-color 0.3s',
                            zIndex: 2
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
                    >
                        ‹
                    </button>
                    <button
                        onClick={handleNextImage}
                        style={{
                            position: 'absolute',
                            right: '20px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'rgba(255, 255, 255, 0.1)',
                            border: 'none',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer',
                            fontSize: '30px',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'background-color 0.3s',
                            zIndex: 2
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
                    >
                        ›
                    </button>

                    {/* Main image container */}
                    <div style={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '60px 0',
                        position: 'relative'
                    }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: '20px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 3,
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '8px 16px',
                                borderRadius: '20px',
                                color: '#000',
                                fontWeight: 500,
                                fontSize: '1rem',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                            }}
                        >
                            {photos[currentImageIndex]?.name || 
                             photos[currentImageIndex]?.label || 
                             `Photo ${currentImageIndex + 1}`}
                        </div>
                        <img
                            src={photos[currentImageIndex]?.url}
                            alt={photos[currentImageIndex]?.label || `Photo ${currentImageIndex + 1}`}
                            style={{
                                maxWidth: '90%',
                                maxHeight: 'calc(100vh - 200px)',
                                objectFit: 'contain'
                            }}
                        />
                    </div>

                    {/* Thumbnails */}
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        gap: '10px',
                        padding: '20px',
                        justifyContent: 'center',
                        background: 'rgba(0, 0, 0, 0.8)',
                        borderTop: '1px solid rgba(255, 255, 255, 0.1)'
                    }}>
                        {photos.map((photo, index) => (
                            <div
                                key={index}
                                onClick={() => handleThumbnailClick(index)}
                                style={{
                                    width: '100px',
                                    height: '75px',
                                    flexShrink: 0,
                                    cursor: 'pointer',
                                    border: currentImageIndex === index ? '2px solid #fff' : '2px solid transparent',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    opacity: currentImageIndex === index ? 1 : 0.6,
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <img
                                    src={photo.url}
                                    alt={photo.label || `Thumbnail ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default PhotoModal;
