import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Modal,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  InputLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { FiUpload } from "react-icons/fi";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { storage, db } from '../../firebase/config'; // Ensure correct Firebase imports
import { useMeta } from '../../hooks/useMeta';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  maxHeight: '90vh',
  overflow: 'auto'
};

const CenterForm = ({ 
  open, 
  onClose, 
  onSubmit, 
  editMode, 
  initialData, 
  theme, 
  operators,
  proposalId, 
  proposedOptions 
}) => {
  const { cities, getMicromarketsForCity } = useMeta();
  const [formData, setFormData] = useState({
    operatorName: '',
    centerName: '',
    city: '',
    micromarket: '',
    address:'',
    locality: '',
    landmark: '',
    googleMapsLocation: '',
    centerManagerName: '',
    centerManagerPhone: '',
    centerManagerEmail: '',
    operationalFrom: '',
    description: '',
    buildingType: '',
    buildingGrade: '',
    nearestMetro: '',
    operatingHours: '',
    latitude: '',
    longitude: '',
    canSupport24x7: '',
    remarks: '',
    amenities: [], 
    cafeteriaCapacity: '',
    listPrice: '',
    closingPrice: '',
    carParkingCost: '',
    bikeParkingCost: '',
    active: false,
    photos: [],
    virtualTours: [] 
  });

  const [errors, setErrors] = useState({});
  const [photos, setPhotos] = useState([{ type: '', file: null, description: '', url: null }]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editMode && initialData) {
      let amenitiesArray = [];
      if (Array.isArray(initialData.amenities)) {
        amenitiesArray = initialData.amenities;
      } else if (typeof initialData.amenities === 'object' && initialData.amenities !== null) {
        amenitiesArray = Object.entries(initialData.amenities)
          .filter(([_, value]) => value)
          .map(([key, _]) => {
            const amenityName = key
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, str => str.toUpperCase());
            return amenityName.trim();
          });
      }

      setFormData({
        ...initialData,
        amenities: amenitiesArray,
        virtualTours: initialData.virtualTours || [] 
      });
      if (initialData.photos && initialData.photos.length > 0) {
        setPhotos(initialData.photos.map(photo => ({
          type: photo.type || '',
          description: photo.description || '',
          url: photo.url, 
          file: null 
        })));
      }
    }
  }, [editMode, initialData]);

  const PHOTO_TYPES = [
    "Building Exterior",
    "Building Reception",
    "Cafeteria",
    "Common Area",
    "Common Conference Room",
    "Lift Lobby",
    "Other Amenity",
    "Pantry",
    "Parking",
    "Phone Booth",
    "Reception",
    "Reception Area",
    "Representative Cabin"
  ];

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedPhotos = [...photos];
      updatedPhotos[index] = {
        ...updatedPhotos[index],
        file: file,
        url: null 
      };
      setPhotos(updatedPhotos);
    }
  };

  const handleAddPhoto = () => {
    setPhotos([...photos, { type: '', file: null, description: '', url: null }]);
  };

  const handleRemovePhoto = async (index) => {
    try {
      const photoToRemove = photos[index];
      
      if (editMode && photoToRemove.url) {
        const centerRef = doc(db, 'centers', initialData.id);
        
        await updateDoc(centerRef, {
          photos: formData.photos.filter((_, i) => i !== index)
        });
        
        setFormData(prev => ({
          ...prev,
          photos: prev.photos.filter((_, i) => i !== index)
        }));
        
        const storageRef = ref(storage, `centers_photos/${photoToRemove.url.split('/').pop()}`);
        await deleteObject(storageRef);
      }
      
      const newPhotos = photos.filter((_, i) => i !== index);
      setPhotos(newPhotos);
    } catch (error) {
      console.error('Error removing photo:', error);
    }
  };

  const handlePhotoChange = (index, field, value) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index] = {
      ...updatedPhotos[index],
      [field]: value
    };
    setPhotos(updatedPhotos);
  };

  const handleAddVirtualTour = () => {
    setFormData(prev => ({
      ...prev,
      virtualTours: [
        ...prev.virtualTours,
        { name: '', url: '', description: '' }
      ]
    }));
  };

  const handleRemoveVirtualTour = (index) => {
    setFormData(prev => ({
      ...prev,
      virtualTours: prev.virtualTours.filter((_, i) => i !== index)
    }));
  };

  const handleVirtualTourChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      virtualTours: prev.virtualTours.map((tour, i) => 
        i === index ? { ...tour, [field]: value } : tour
      )
    }));
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name.startsWith('amenities.')) {
      const amenityName = name.split('.')[1]
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();

      setFormData(prev => ({
        ...prev,
        amenities: checked 
          ? [...prev.amenities, amenityName]
          : prev.amenities.filter(item => item !== amenityName)
      }));
    } else if (name === 'city') {
      setFormData(prev => ({
        ...prev,
        [name]: value,
        micromarket: ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: name === 'active' ? checked : value
      }));
    }

    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);
      const photoUrls = await Promise.all(
        photos
          .filter(photo => photo.file || photo.url)
          .map(async (photo) => {
            if (photo.file) {
              const photoRef = ref(storage, `center_photos/${uuidv4()}`);
              await uploadBytes(photoRef, photo.file);
              const url = await getDownloadURL(photoRef);
              return {
                type: photo.type,
                url,
                description: photo.description
              };
            }
            delete photo.file;
            return photo;
          })
      );

      const validVirtualTours = formData.virtualTours.filter(tour => tour.name || tour.url || tour.description);
      
      console.log('Valid Virtual Tours:', validVirtualTours);

      const updatedFormData = {
        ...formData,
        photos: photoUrls,
        virtualTours: validVirtualTours.map(tour => ({
          name: tour.name?.trim() || '',
          url: tour.url?.trim() || '',
          description: tour.description?.trim() || ''
        }))
      };

      console.log('Updated Form Data:', updatedFormData);

      // Update center document
      await onSubmit(updatedFormData);

      //TODO  : To do this later
      /*
      // Update all proposals that reference this center
      const proposalsRef = collection(db, 'proposals');
      const querySnapshot = await getDocs(proposalsRef);

      console.log('Total proposals found:', querySnapshot.docs.length);

      const updatePromises = querySnapshot.docs.map(async (proposalDoc) => {
        const proposalData = proposalDoc.data();
        let needsUpdate = false;
        
        // Check if this proposal contains our center
        const updatedOptions = proposalData.proposedOptions?.map(option => {
          if (option.centerId === formData.id || option.centerName === formData.centerName) {
            needsUpdate = true;
            console.log('Updating center in proposal:', proposalDoc.id);
            // Make sure we're not setting any undefined values
            return {
              ...option,
              centerName: formData.centerName || option.centerName,
              centerId: formData.id || option.centerId,
              city: formData.city || option.city,
              state: formData.state || option.state,
              address: formData.address || option.address,
              photos: formData.photos || option.photos,
              virtualTours: formData.virtualTours || option.virtualTours,
            };
          }
          return option;
        }) || [];

        if (needsUpdate) {
          console.log('Updating proposal:', proposalDoc.id);
          console.log('Updated options:', updatedOptions);
          
          // Update the proposal document
          const proposalRef = doc(db, 'proposals', proposalDoc.id);
          return updateDoc(proposalRef, {
            proposedOptions: updatedOptions
          });
        }
        return Promise.resolve();
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);
      */
      handleClose();
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setFormData({
      operatorName: '',
      centerName: '',
      city: '',
      micromarket: '',
      address:'',
      locality: '',
      landmark: '',
      googleMapsLocation: '',
      centerManagerName: '',
      centerManagerPhone: '',
      centerManagerEmail: '',
      operationalFrom: '',
      description: '',
      buildingType: '',
      buildingGrade: '',
      nearestMetro: '',
      operatingHours: '',
      latitude: '',
      longitude: '',
      canSupport24x7: '',
      remarks: '',
      amenities: [], 
      cafeteriaCapacity: '',
      listPrice: '',
      closingPrice: '',
      carParkingCost: '',
      bikeParkingCost: '',
      active: false,
      photos: [],
      virtualTours: []
    });
    setErrors({});
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.operatorName) {
      newErrors.operatorName = 'Operator Name is required';
    }

    if (!formData.centerName) {
      newErrors.centerName = 'Center Name is required';
    }

    if (!formData.city) {
      newErrors.city = 'City is required';
    }

    if (!formData.micromarket) {
      newErrors.micromarket = 'Micromarket is required';
    }

    if (!formData.locality) {
      newErrors.locality = 'Locality is required';
    }
    if (!formData.address) {
      newErrors.address = 'Address is required';
    }

    if (!formData.landmark) {
      newErrors.landmark = 'Landmark is required';
    }

    // if (!formData.googleMapsLocation) {
    //   newErrors.googleMapsLocation = 'Google Maps Location is required';
    // }

    // if (!formData.operationalFrom) {
    //   newErrors.operationalFrom = 'Operational From is required';
    // }

    if (!formData.latitude) {
      newErrors.latitude = 'Latitude is required';
    }

    if (!formData.longitude) {
      newErrors.longitude = 'Longitude is required';
    }

    if (!formData.listPrice) {
      newErrors.listPrice = 'List Price is required';
    }

    const invalidTours = formData.virtualTours.some(tour => {
      const hasName = tour.name.trim() !== '';
      const hasUrl = tour.url.trim() !== '';
      return (hasName || hasUrl) && !(hasName && hasUrl);
    });

    if (invalidTours) {
      newErrors.virtualTours = 'All virtual tours must have both a name and URL';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="center-modal-title"
    >
      <Box sx={modalStyle}>
        {/* Header */}
        <Box sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #E5E7EB',
          marginBottom: 2
        }}>
          <Typography variant="h5" component="h3">
            Center Details
          </Typography>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
                bgcolor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Form Content */}
        <Box sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Operator Name <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <FormControl fullWidth size="small" error={!!errors.operatorName}>
                      <Select
                        value={formData.operatorName}
                        onChange={handleChange}
                        name="operatorName"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>-Select-</MenuItem>
                        {operators?.map((operator) => (
                          <MenuItem key={operator.id} value={operator.operatorName}>
                            {operator.operatorName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.operatorName && (
                        <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                          {errors.operatorName}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Center Name <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.centerName}
                      onChange={handleChange}
                      name="centerName"
                      size="small"
                      error={!!errors.centerName}
                    />
                    {errors.centerName && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.centerName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      City <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.city}
                        onChange={handleChange}
                        name="city"
                        displayEmpty
                        error={!!errors.city}
                      >
                        <MenuItem value="" disabled>-Select City-</MenuItem>
                        {cities.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.city && (
                        <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                          {errors.city}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Micromarket <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.micromarket}
                        onChange={handleChange}
                        name="micromarket"
                        displayEmpty
                        error={!!errors.micromarket}
                      >
                        <MenuItem value="" disabled>-Select Micromarket-</MenuItem>
                        {formData.city && getMicromarketsForCity(formData.city)?.map((micromarket) => (
                          <MenuItem key={micromarket} value={micromarket}>
                            {micromarket}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.micromarket && (
                        <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                          {errors.micromarket}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Address <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.address}
                      onChange={handleChange}
                      name="address"
                      size="small"
                      error={!!errors.address}
                    />
                    {errors.address && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.locality}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Locality <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.locality}
                      onChange={handleChange}
                      name="locality"
                      size="small"
                      error={!!errors.locality}
                    />
                    {errors.locality && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.locality}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Landmark <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.landmark}
                      onChange={handleChange}
                      name="landmark"
                      size="small"
                      error={!!errors.landmark}
                    />
                    {errors.landmark && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.landmark}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Google Maps Location
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.googleMapsLocation}
                      onChange={handleChange}
                      name="googleMapsLocation"
                      size="small"
                      error={!!errors.googleMapsLocation}
                    />
                    {errors.googleMapsLocation && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.googleMapsLocation}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Latitude <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.latitude}
                      onChange={handleChange}
                      name="latitude"
                      size="small"
                      error={!!errors.latitude}
                    />
                    {errors.latitude && (
                      <Typography variant="caption" color="error" sx={{ display: 'block', ml: 2 }}>
                        {errors.latitude}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Longitude <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.longitude}
                      onChange={handleChange}
                      name="longitude"
                      size="small"
                      error={!!errors.longitude}
                    />
                    {errors.longitude && (
                      <Typography variant="caption" color="error" sx={{ display: 'block', ml: 2 }}>
                        {errors.longitude}
                      </Typography>
                    )}
                  </Grid>
                  {/* Operational From */}
                  <Grid item xs={12} sx={{ pr: 2 }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Operational From
                    </Typography>
                    <TextField
                      fullWidth
                      type="date"
                      value={formData.operationalFrom}
                      onChange={handleChange}
                      name="operationalFrom"
                      size="small"
                      error={!!errors.operationalFrom}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.operationalFrom && (
                      <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                        {errors.operationalFrom}
                      </Typography>
                    )}
                  </Grid>

                  {/* Active */}
                  <Grid item xs={6} >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.active}
                          onChange={handleChange}
                          name="active"
                          sx={{
                            color: theme?.palette.primary.main,
                            '&.Mui-checked': {
                              color: theme?.palette.primary.main,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography variant="subtitle2" color="text.secondary">
                          Active <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                        </Typography>
                      }
                    />
                    {errors.active && (
                      <Typography variant="caption" color="error" sx={{ display: 'block', ml: 2 }}>
                        {errors.active}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Manager Information */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                  Center Manager Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Center Manager Name
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.centerManagerName}
                      onChange={handleChange}
                      name="centerManagerName"
                      size="small"
                      placeholder="First Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Center Manager Phone
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.centerManagerPhone}
                      onChange={handleChange}
                      name="centerManagerPhone"
                      size="small"

                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Center Manager Email
                    </Typography>
                    <TextField
                      fullWidth
                      type="email"
                      value={formData.centerManagerEmail}
                      onChange={handleChange}
                      name="centerManagerEmail"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Additional Details */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                  Additional Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Building Type
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.buildingType}
                        onChange={handleChange}
                        name="buildingType"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>-Select-</MenuItem>
                        <MenuItem value="Tech Park">Tech Park</MenuItem>
                        <MenuItem value="Standalone Building">Standalone Building</MenuItem>
                        <MenuItem value="Floor in Commercial Building">Floor in Commercial Building</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Building Grade
                    </Typography>
                    <FormControl fullWidth size="small">
                      <Select
                        value={formData.buildingGrade}
                        onChange={handleChange}
                        name="buildingGrade"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>-Select-</MenuItem>
                        <MenuItem value="a">Grade A</MenuItem>
                        <MenuItem value="b">Grade B</MenuItem>
                        <MenuItem value="c">Grade C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Nearest Metro
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.nearestMetro}
                      onChange={handleChange}
                      name="nearestMetro"
                      size="small"
                      placeholder="None"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Operating Hours
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.operatingHours}
                      onChange={handleChange}
                      name="operatingHours"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Can Support 24/7?
                    </Typography>
                    <FormControl fullWidth size="small" error={!!errors.canSupport24x7}>
                      <Select
                        value={formData.canSupport24x7}
                        onChange={handleChange}
                        name="canSupport24x7"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>-Select-</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                      {errors.canSupport24x7 && (
                        <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                          {errors.canSupport24x7}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Remarks
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.remarks}
                      onChange={handleChange}
                      name="remarks"
                      size="small"
                      placeholder="Enter any additional remarks"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Amenities */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                  Amenities
                </Typography>
                <Grid container spacing={2}>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Shared Internet")}
                          onChange={handleChange}
                          name="amenities.sharedInternet"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Shared Internet"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Access Card System")}
                          onChange={handleChange}
                          name="amenities.accessCardSystem"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Access Card System"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Meeting Rooms")}
                          onChange={handleChange}
                          name="amenities.meetingRooms"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Meeting Rooms"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Recreational Space")}
                          onChange={handleChange}
                          name="amenities.recreationalSpace"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Recreational Space"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Gym")}
                          onChange={handleChange}
                          name="amenities.gym"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Gym"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Food Court")}
                          onChange={handleChange}
                          name="amenities.foodCourt"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Food Court"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Wellness Room")}
                          onChange={handleChange}
                          name="amenities.wellnessRoom"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Wellness Room"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.amenities.includes("Vending Machine")}
                          onChange={handleChange}
                          name="amenities.vendingMachine"
                          sx={{
                            color: '#26A69A',
                            '&.Mui-checked': {
                              color: '#26A69A',
                            },
                          }}
                        />
                      }
                      label="Vending Machine"
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex' }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Cafeteria Capacity
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={formData.cafeteriaCapacity}
                      onChange={handleChange}
                      name="cafeteriaCapacity"
                      placeholder="######"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Pricing */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'primary.main', mb: 2 }}>
                  Pricing
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      List Price <Box component="span" sx={{ color: 'error.main' }}>*</Box>
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.listPrice}
                      onChange={handleChange}
                      name="listPrice"
                      size="small"
                      error={!!errors.listPrice}
                    />
                    {errors.listPrice && (
                      <Typography variant="caption" color="error" sx={{ display: 'block', ml: 2 }}>
                        {errors.listPrice}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Closing Price
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.closingPrice}
                      onChange={handleChange}
                      name="closingPrice"
                      size="small"
                      placeholder="######"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Car Parking Cost
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.carParkingCost}
                      onChange={handleChange}
                      name="carParkingCost"
                      size="small"
                      placeholder="######"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Bike Parking Cost
                    </Typography>
                    <TextField
                      fullWidth
                      value={formData.bikeParkingCost}
                      onChange={handleChange}
                      name="bikeParkingCost"
                      size="small"
                      placeholder="######"
                    />
                  </Grid>
                </Grid>
                {/* Photos Section */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Center Photos
                  </Typography>
                  {photos.map((photo, index) => (
                    <Grid container spacing={2} key={index} sx={{ mb: 2 }} alignItems="center">
                      <Grid item xs={4}>
                        <FormControl fullWidth size="small">
                          <InputLabel id={`photo-type-label-${index}`}>Photo Type</InputLabel>
                          <Select
                            labelId={`photo-type-label-${index}`}
                            value={photo.type}
                            label="Photo Type"
                            onChange={(e) => handlePhotoChange(index, "type", e.target.value)}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>
                              -Select Type-
                            </MenuItem>
                            {PHOTO_TYPES.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        {photo.url ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <img 
                              src={photo.url} 
                              alt={photo.type} 
                              style={{ 
                                width: '60px', 
                                height: '60px', 
                                objectFit: 'cover',
                                borderRadius: '4px'
                              }} 
                            />
                          </Box>
                        ) : (
                          <Button
                            variant="outlined"
                            component="label"
                            startIcon={<FiUpload />}
                            sx={{ height: '56px', width: '100%' }}
                          >
                            Upload Photo
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(e) => handleFileChange(e, index)}
                            />
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Description"
                          value={photo.description}
                          onChange={(e) => handlePhotoChange(index, "description", e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => handleRemovePhoto(index)}
                          color="error"
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={handleAddPhoto}
                    sx={{ mt: 1 }}
                  >
                    Add Another Photo
                  </Button>
                </Box>
              </Grid>

              {/* Virtual Tours Section */}
              <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Virtual Tours
                  </Typography>
                  
                  {formData.virtualTours.map((tour, index) => (
                    <Box key={index} sx={{ mb: 2, p: 2, borderRadius: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Name
                              </Typography>
                              <TextField
                                fullWidth
                                size="small"
                                value={tour.name}
                                onChange={(e) => handleVirtualTourChange(index, 'name', e.target.value)}
                                placeholder="e.g., Main Area Tour"
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                URL
                              </Typography>
                              <TextField
                                fullWidth
                                size="small"
                                value={tour.url}
                                onChange={(e) => handleVirtualTourChange(index, 'url', e.target.value)}
                                placeholder="https://..."
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Description
                              </Typography>
                              <TextField
                                fullWidth
                                size="small"
                                value={tour.description}
                                onChange={(e) => handleVirtualTourChange(index, 'description', e.target.value)}
                                placeholder="Brief description of the tour"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <IconButton 
                            onClick={() => handleRemoveVirtualTour(index)}
                            sx={{ color: 'error.main' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                  
                  <Button
                    variant="outlined"
                    onClick={handleAddVirtualTour}
                    sx={{
                      mt: 1
                    }}
                  >
                    Add Virtual Tour
                  </Button>
                  {errors.virtualTours && (
                    <Typography variant="caption" color="error" sx={{ mt: 0.5, ml: 1 }}>
                      {errors.virtualTours}
                    </Typography>
                  )}
                </Box>
              </Grid>

              {/* Submit Buttons */}
              <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  mt: 3,
                  borderTop: '1px solid #E5E7EB',
                  pt: 3
                }}>
                  <Button
                    variant="outlined"
                    onClick={handleReset}
                    sx={{
                      borderColor: theme?.palette.primary.main,
                      color: theme?.palette.primary.main,
                      '&:hover': {
                        borderColor: theme?.palette.primary.dark,
                        backgroundColor: 'transparent',
                      }
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      bgcolor: theme?.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme?.palette.primary.dark
                      }
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      editMode ? 'Update Center' : 'Add Center'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>

      </Box>
    </Modal>
  );
};

export default CenterForm;
