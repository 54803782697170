import { useSelector } from 'react-redux';
import { 
  selectCities, 
  selectMicromarkets, 
  selectMicromarketsByCity, 
  selectIsLoading, 
  selectError 
} from '../redux/slices/metaSlice';

export const useMeta = () => {
  const cities = useSelector(selectCities);
  const micromarkets = useSelector(selectMicromarkets);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const micromarketsByCity = useSelector(selectMicromarketsByCity);

  const getMicromarketsForCity = (city) => {
    return micromarketsByCity[city];
  };

  return {
    cities,
    micromarkets,
    getMicromarketsForCity,
    isLoading,
    error
  };
};

export default useMeta;
