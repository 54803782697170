import { configureStore, combineReducers } from '@reduxjs/toolkit';
import actionItemsReducer from './slices/actionItemsSlice';
import leadsReducer from './slices/leadsSlice';
import proposalsReducer from './slices/proposalsSlice';
import userReducer from './slices/userSlice';
import metaReducer from './slices/metaSlice';

const rootReducer = combineReducers({
  actionItems: actionItemsReducer,
  leads: leadsReducer,
  proposals: proposalsReducer,
  user: userReducer,
  meta: metaReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['payload.timestamp'],
        ignoredPaths: [
          'payload.timestamp',
          'meta.timestamp',
          'leads.data',
          'proposals.data',
          'actionItems.data',
        ],
      },
    }),
});

export default store;
