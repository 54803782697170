import React, { useState, useEffect } from 'react'
import OfficeProposal from '../../Component/Public/OfficeProposal/OfficeProposal'
import RecommendedSpaces from '../../Component/Public/RecommendedSpaces/Recommendedspaces'
import Centerdetailscard from '../../Component/Public/Centerdetails/Centerdetailscard'
import Conculsion from '../../Component/Public/Conculsion/Conculsion'
import Header from '../../Component/Public/Headercomponet/Header'
import Recommandedlocation from '../../Component/Public/Recommanded Location/Recommandedlocation'
import MobileNavigation from '../../Component/Public/MobileNavigation/MobileNavigation'
import ShortlistTable from '../../Component/Public/Shortlist Place/ShortlistTable'
import {
    saveClientName,
    saveRequirements,
    saveCenter,
    deleteCenter,
    addToProposal,
    reorderCenters,
    deleteCenterinProposel
} from '../../Services/FirebaseUtils';
import ChatDialog from '../../Component/Public/Chat/ChatDialog';
import VisitSchedule from './VisitSchedule'

const Screen = ({
    proposal,
    proposalId,
    shortlistedCenters,
    schedules,
    isEditable = false
}) => {
    const [localProposal, setLocalProposal] = useState(proposal);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        setLocalProposal(proposal);
    }, [proposal]);

    const handleProposalUpdate = async (updatedProposal) => {
        try {
            if (!updatedProposal?.proposedOptions || !proposalId) {
                console.error('Missing required data for update');
                return;
            }

            // Update local state immediately for better UX
            setLocalProposal(updatedProposal);

            // Update Firebase
            const result = await reorderCenters(proposalId, updatedProposal.proposedOptions);

            if (!result.success) {
                // Revert local state if Firebase update fails
                setLocalProposal(proposal);
                console.error('Failed to update proposal:', result.error);
            }
        } catch (error) {
            // Revert local state on error
            setLocalProposal(proposal);
            console.error('Error updating proposal:', error);
        }
    };

    const handleSaveClientName = async (editedName) => {
        try {
            const result = await saveClientName(proposalId, editedName);

            if (result.success) {
                const updatedProposal = {
                    ...localProposal,
                    clientName: editedName
                };
                handleProposalUpdate(updatedProposal);
                return true;
            } else {
                console.error('Error saving client name:', result.error);
                return false;
            }
        } catch (error) {
            console.error('Error in handleSaveClientName:', error);
            return false;
        }
    };

    const handleSaveRequirements = async (editedRequirements) => {
        try {
            const result = await saveRequirements(proposalId, editedRequirements);

            if (result.success) {
                const updatedProposal = {
                    ...localProposal,
                    requirements: result.requirements
                };
                handleProposalUpdate(updatedProposal);
                return true;
            } else {
                console.error('Error saving requirements:', result.error);
                return false;
            }
        } catch (error) {
            console.error('Error in handleSaveRequirements:', error);
            return false;
        }
    };

    const handleSaveCenter = async (updatedCenter, selectedIndex) => {
        try {
            const result = await saveCenter(
                proposalId,
                updatedCenter,
                localProposal.proposedOptions,
                selectedIndex
            );

            if (result.success) {
                handleProposalUpdate({
                    ...localProposal,
                    proposedOptions: result.updatedOptions
                });
                return true;
            } else {
                console.error('Error saving center:', result.error);
                return false;
            }
        } catch (error) {
            console.error('Error in handleSaveCenter:', error);
            return false;
        }
    };

    const handleDeleteCenter = async (centerToDelete) => {
        // TODO :  Delete the option from the proposal
        try {
            const result = await deleteCenterinProposel(
                proposalId,
                centerToDelete,
                localProposal.proposedOptions
            );

            if (result.success) {
                handleProposalUpdate({
                    ...localProposal,
                    proposedOptions: result.updatedOptions
                });
                return true;
            } else {
                console.error('Error deleting center:', result.error);
                return false;
            }
        } catch (error) {
            console.error('Error in handleDeleteCenter:', error);
            return false;
        }
    };

    const handleAddToProposal = async (selectedOption) => {
        try {
            const result = await addToProposal(proposalId, selectedOption);

            if (result.success) {
                handleProposalUpdate({
                    ...localProposal,
                    ...result.updatedProposal
                });
                return true;
            } else {
                console.error('Error adding to proposal:', result.error);
                return false;
            }
        } catch (error) {
            console.error('Error in handleAddToProposal:', error);
            return false;
        }
    };

    return (
        <div>
            {!isEditable && <Header proposal={localProposal} isEditable={isEditable} onSaveClientName={handleSaveClientName} />}
            <div id="office-proposal">
                <OfficeProposal
                    proposal={localProposal}
                    isEditable={isEditable}
                    onSaveClientName={handleSaveClientName}
                    onSaveRequirements={handleSaveRequirements}
                />
            </div>
            <div>
                <VisitSchedule
                    proposal={localProposal}
                    proposalId={proposalId}
                    shortlistedCenters={shortlistedCenters}
                    isEditable={isEditable}
                />
            </div>
            {/* {!isEditable && ( */}
            <div id="Short-list">
                <ShortlistTable
                    proposal={localProposal}
                    proposalId={proposalId}
                    shortlistedCenters={shortlistedCenters}
                    schedules={schedules}
                    isEditable={isEditable}
                />
            </div>
            {/* )} */}
            {Array.isArray(localProposal?.proposedOptions) && localProposal.proposedOptions.length > 0 && (
                <div id="recommended-locations">
                    <Recommandedlocation
                        proposal={localProposal}
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                    />
                </div>
            )}
            <div id="recommended-spaces">
                <RecommendedSpaces
                    proposal={localProposal}
                    isEditable={isEditable}
                    onSaveCenter={handleSaveCenter}
                    onDeleteCenter={handleDeleteCenter}
                    onAddToProposal={handleAddToProposal}
                    onUpdateProposal={handleProposalUpdate}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                />
            </div>
            <div id="center-details">
                <Centerdetailscard
                    proposal={localProposal}
                    proposalId={proposalId}
                    shortlistedCenters={shortlistedCenters}
                    isEditable={isEditable}
                />
            </div>
            <Conculsion proposal={localProposal} />
            <MobileNavigation />
            {!isEditable && <ChatDialog proposal={localProposal} schedules={schedules} />}
        </div>
    );
};

export default Screen;
