import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { auth } from '../../../firebase/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { scheduleVisit, deleteVisitSchedule } from '../../../Services/FirebaseUtils';

// Generate time slots from 9 AM to 5 PM with 30-minute intervals
const generateTimeSlots = () => {
    const slots = [];
    const start = 9; // 9 AM
    const end = 17; // 5 PM

    for (let hour = start; hour <= end; hour++) {
        for (let minute of ['00', '30']) {
            if (hour === end && minute === '30') continue; // Skip 5:30 PM
            const time24 = `${hour.toString().padStart(2, '0')}:${minute}`;
            const hour12 = hour > 12 ? hour - 12 : hour;
            const period = hour >= 12 ? 'PM' : 'AM';
            const time12 = `${hour12}:${minute} ${period}`;
            slots.push({ value: time24, label: time12 });
        }
    }
    return slots;
};

const TIME_SLOTS = generateTimeSlots();

const ScheduleModal = ({ show, handleClose, centerId, proposalId, currentSchedule }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleSchedule = async () => {
        if (!auth.currentUser || !selectedDate || !selectedTime) return;

        try {
            const result = await scheduleVisit(
                proposalId,
                centerId,
                selectedDate,
                selectedTime,
                auth.currentUser.email
            );

            if (result.success) {
                handleClose();
            } else {
                console.error('Failed to schedule visit:', result.error);
            }
        } catch (error) {
            console.error('Error in handleSchedule:', error);
        }
    };

    const handleDelete = async () => {
        if (!auth.currentUser) return;

        try {
            const result = await deleteVisitSchedule(proposalId, centerId);
            
            if (result.success) {
                setShowDeleteConfirm(false);
                handleClose();
            } else {
                console.error('Failed to delete visit schedule:', result.error);
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                        Schedule Visit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Select Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        min={new Date().toISOString().split('T')[0]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Select Time</Form.Label>
                                    <Form.Select
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    >
                                        <option value="">Choose time...</option>
                                        {TIME_SLOTS.map(slot => (
                                            <option key={slot.value} value={slot.value}>
                                                {slot.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {currentSchedule && (
                        <Button 
                            variant="danger" 
                            className="me-auto"
                            onClick={() => setShowDeleteConfirm(true)}
                        >
                            Delete Schedule
                        </Button>
                    )}
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSchedule}
                        disabled={!selectedDate || !selectedTime}
                    >
                        Schedule Visit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this visit schedule?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScheduleModal;
